import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby'

const Blog = () => (
  <StaticQuery
    query={graphql`
    query BlogAllPostQuery {
      allWordpressPost(sort: { fields: [date], order:DESC }) {
        edges {
          node {
            date(formatString: "DD, MMM YYYY")
            title
            excerpt
            author{
              name
            }
            categories {
              id
              name
            }
            featured_media{
              localFile{
                childImageSharp{
                  id
                  sizes( maxWidth: 1000 ) {
                      ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            slug
          }
        }
      }
    }
  `}
    render={data => (
      <Layout id="wrapper">
      <Helmet
        title= 'Blog post'
        meta={[
          { name: 'description', content: 'This is content' },
        ]}
        bodyAttributes={{
          class: 'blog blog-archive'
        }}
      />
        <div className="inner container">

          <div className="row">
            <div className="col col-sm-12">
              <div className="blog-grids">
                {data.allWordpressPost.edges.map(({ node }) => {



                  return (
                    <div key={node.slug} className="grid">
                      <div className="entry-media">
                        { node.featured_media && node.featured_media.localFile && node.featured_media.localFile.childImageSharp && (
                        <Img
                          src={node.featured_media.localFile.childImageSharp.sizes.src}
                          sizes={node.featured_media.localFile.childImageSharp.sizes}
                          alt="blog_image_one"
                          className="img-fluid"
                        />
                        )}
                      </div>
                      <div className="entry-body">
                        <span className="cat">{node.categories && node.categories.map(category => `${category.name}, `)}</span>
                        <h3><Link to={`${node.slug}`} cover bg="#000000" dangerouslySetInnerHTML={{ __html: node.title }} /></h3>
                        <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                        <div className="read-more-date">
                          <Link to={node.slug} cover bg="#000000">Read More..</Link>
                          <span className="date">{node.date}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>)
}
  />
);


export default Blog;